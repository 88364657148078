import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '../../Avatar/Avatar'

import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton/IconButton'
import LockIcon from '@material-ui/icons/Lock'
import CheckCircle from '@material-ui/icons/CheckCircle'
import CircularProgress from '@material-ui/core/CircularProgress'
import Blink from '../../Blink'
import GoogleAnalytics from '../../../lib/GoogleAnalytics'
import RedirectToStoreDialog from '../../RedirectToStoreDialog/RedirectToStoreDialog'

import cn from 'classnames'

type Props = {
  /** url to the avatar logo  */
  logo: string
  /** the title to be showing in header */
  brandShortUrl: string
  /** the deliveryTime to be showing in header */
  shopReference: string
  onClose?: () => void

  brandName?: string
  brandWebsite?: string

  onCheckedBrands?: (brandName: string) => void
  checkedBrands?: any
  commissionBalance: number
  shopUrl?: string
  discountPercentage: number
  userId: string
  settingsId?: string
  selectedCountry?: string
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.white,
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: 14,
    fontWeight: 800,
    fontFamily: 'Helvetica',
    lineHeight: '10px',
    paddingTop: 5,
    paddingBottom: 2,
    margin: 0,
    color: 'black',
    '& span': {
      marginRight: 4,
      lineHeight: '16px',

      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'inline-block',
      maxWidth: '200px',
      [theme.breakpoints.up('xs')]: {
        maxWidth: 'none',
      },
    },
    display: 'flex',
    alignItems: 'center',
  },
  secureIcon: {
    fontSize: 13,
    lineHeight: '13.8px',
    marginBottom: -2,
    paddingRight: 3,
  },
  checkIcon: {
    fontSize: 14,
    lineHeight: '13.8px',
    marginBottom: -2,
    color: theme.palette.blue,
  },
  progressIcon: {
    fontSize: 12,
    lineHeight: '13.8px',
    color: theme.palette.gray,
    marginBottom: -1,
    marginLeft: 1,
  },
  deliveryTime: {
    fontSize: 12,
    fontStyle: 'italic',
    color: theme.palette.gray,
    fontFamily: 'Helvetica',
    lineHeight: '13.8px',
  },
  logo: {
    margin: '12px 16px',
    position: 'relative',
    '&:before': {
      content: '" "',
      position: 'absolute',
      top: -3,
      left: -3,
      right: -3,
      bottom: -3,
      borderRadius: '100%',
      opacity: 0.94,
      border: '1px solid rgba(0, 0, 0, 0.1)',
      boxSizing: 'border-box',
    },
  },
  menuIcon: {
    fontSize: '2rem',
    width: 27,
    height: 27,
    color: 'rgba(0,0,0,0.2)!important',
  },
  closeButton: {
    margin: '0 16px',
  },
  clearLink: {
    pointerEvents: 'none',
    textDecoration: 'none',
  },
}))

export function ProductHeader({
  logo,
  brandShortUrl,
  onClose,
  brandName,
  onCheckedBrands,
  checkedBrands,
  shopReference,
  commissionBalance,
  shopUrl,
  discountPercentage,
  userId,
  settingsId,
  selectedCountry,
}: Props) {
  const classes = useStyles()

  const [step, setStep] = useState(-1)
  const [open, setOpen] = useState(false)

  const trackSetupYourOwnMyShop = () => {
    GoogleAnalytics.track({
      category: 'Product',
      action: `Navigate To Brand's Webshop (Go To ${brandShortUrl})`,
    })
  }

  useEffect(() => {
    if (!checkedBrands) {
      return
    }
    if (!checkedBrands[brandName] && step === -1) {
      setStep(0)
      return
    }
    if (!checkedBrands[brandName] && step === 0) {
      setTimeout(() => {
        setStep(1)
      }, 2000)
      setTimeout(() => {
        setStep(2)
        onCheckedBrands(brandName)
      }, 4000)
    }
  }, [brandName, checkedBrands, onCheckedBrands, step])

  const unavailableBalance = !parseInt(commissionBalance.toString())

  const redirectToOriginalStore = () => {
    if (unavailableBalance) return
    setOpen(true)
    trackSetupYourOwnMyShop()
  }

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        {/* eslint-disable-next-line */}
        <a
          href={'#'}
          rel="noopener noreferrer"
          onClick={redirectToOriginalStore}
          className={unavailableBalance ? classes.clearLink : undefined}
        >
          <Avatar logo={logo} diameter={38} className={classes.logo} />
        </a>
        <div>
          {/* eslint-disable-next-line */}
          <a
            href={'#'}
            onClick={redirectToOriginalStore}
            rel="noopener noreferrer"
            className={cn(
              classes.title,
              unavailableBalance && classes.clearLink,
            )}
          >
            <span>{brandShortUrl}</span>
            {step === 2 || !checkedBrands || checkedBrands[brandName] ? (
              <CheckCircle color="primary" className={classes.checkIcon} />
            ) : (
              <CircularProgress
                className={classes.progressIcon}
                size={12}
                thickness={4}
              />
            )}
          </a>
          {step <= 0 && checkedBrands && !checkedBrands[brandName] ? (
            <span className={classes.deliveryTime}>
              Verifying webshop connection
              <Blink>.</Blink>
              <Blink>.</Blink>
              <Blink>.</Blink>
            </span>
          ) : (
            <></>
          )}
          {step === 1 && checkedBrands && !checkedBrands[brandName] ? (
            <span className={classes.deliveryTime}>
              Checking stock level
              <Blink>.</Blink>
              <Blink>.</Blink>
              <Blink>.</Blink>
            </span>
          ) : (
            <></>
          )}
          {step === 2 || !checkedBrands || checkedBrands[brandName] ? (
            <span className={classes.deliveryTime}>
              <LockIcon fontSize="small" className={classes.secureIcon} />
              Secure webshop connection
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
      {onClose && (
        <div className={classes.closeButton}>
          <IconButton
            onClick={onClose}
            edge="end"
            color="inherit"
            aria-label="menu"
          >
            <CloseIcon className={classes.menuIcon} />
          </IconButton>
        </div>
      )}
      {open ? (
        <RedirectToStoreDialog
          open={open}
          onClose={() => setOpen(false)}
          brandName={brandName}
          discountPercentage={discountPercentage}
          webshopUrl={shopUrl}
          settingsId={settingsId}
          userId={userId}
          shopReference={shopReference}
          selectedCountry={selectedCountry}
        />
      ) : null}
    </div>
  )
}

export default ProductHeader
