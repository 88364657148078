import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { HashLink } from 'react-router-hash-link'
import { Product } from '../../types'

type Props = {
  product: Product
  onClick?: () => void
  scroll?: (el) => void
  shipToSelectedCountry?: boolean
}

const useStyles = (props: { shipToSelectedCountry: boolean }) =>
  makeStyles({
    picture: {
      maxWidth: '100%',
      maxHeight: 300,
      verticalAlign: 'center',
      opacity: props.shipToSelectedCountry ? 1 : 0.5,

      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    pictureNameContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    pictureName: {
      objectFit: 'contain',
      color: '#262626',
      textTransform: 'capitalize',
      fontWeight: 'bold',
    },
  })

function CellProduct({
  product,
  onClick,
  scroll,
  shipToSelectedCountry,
}: Props) {
  const classes = useStyles({ shipToSelectedCountry })()

  return (
    <HashLink to={`#${product.id}`} onClick={onClick} scroll={scroll}>
      {product?.photos[0] ? (
        <img
          alt={product.name || 'product'}
          src={product?.photos[0]}
          className={classes.picture}
        />
      ) : (
        <div className={classes.pictureNameContainer}>
          <p className={classes.pictureName}>{product.name}</p>
        </div>
      )}
    </HashLink>
  )
}

function productPropsAreEqual(prevProps, nextProps) {
  return (
    prevProps?.product.photos[0] === nextProps?.product.photos[0] &&
    prevProps?.shipToSelectedCountry === nextProps?.shipToSelectedCountry
  )
}

export default React.memo(CellProduct, productPropsAreEqual)
