/* eslint-disable  @typescript-eslint/camelcase */
import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button/Button'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import LockIcon from '@material-ui/icons/Lock'
import usePaymentHandler from '../../hooks/usePaymentHandler'
import { Address, Variant, Product } from '../../types'
import { PaymentStatus, stripeStatus } from '../../Constants'
import {
  errorMessageFromCode,
  getCreatePaymentIntentInput,
} from '../../lib/utils'

import moment from 'moment'
import StepsLoader from '../StepsLoader/StepsLoader'
import GoogleAnalytics from '../../lib/GoogleAnalytics'

type Props = {
  orderDetails: {
    variant: Variant
    shippingDetails: Address
    billingDetails: Address
  }
  product: Product
  quantity: number
  onSuccess?: () => void
  setOrderId: (arg: string) => void
  onAddToOrdersHistory: (...args: any[]) => void
  onPrevious?: (args: any) => void
  shippingFlatRate?: number
}

const useStyles = makeStyles((theme: any) => ({
  checkout: {
    width: '100%',
    minHeight: 180,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 25,
    paddingBottom: 0,
    height: 220,
  },
  card: {
    padding: '5px 10px',
    paddingTop: 15,
    width: '100%',
    border: '0.5px solid rgba(0, 0, 0, 0.2)',
    boxSizing: 'border-box',
    borderRadius: 10,
    height: 50,
    background: '#dfdede38',
    [theme.breakpoints.up('sm')]: {
      border: '1px solid rgba(0, 0, 0, 0.2)',
      paddingTop: 14,
    },
  },
  lock: {
    paddingRight: 10,
    fontSize: 30,
    color: '#FFFFFF',
  },
  error: {
    fontSize: 12,
    fontFamily: 'Nunito',
    letterSpacing: '-0.02em',
    color: '#ff1744',
    fontWeight: 600,
  },
  button: {
    ...theme.styles.button,
    height: 50,
    width: '100%',
    ...theme.styles.blueButton,
  },
  subTitle: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '150%',
    color: '#999999',
    letterSpacing: '-0.02em',
    marginBottom: 10,
    fontWeight: 300,
    textAlign: 'center',
    width: '100%',
  },
  changeBilling: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  paymentMethods: {
    backgroundImage: 'url("/static/images/cards.svg")',
    height: 25,
    width: 250,
    marginTop: 10,
    backgroundSize: 'cover',
  },
  center: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  padding: {
    padding: 25,
  },
  paddingTop: {
    paddingTop: 25,
  },
}))

const CARD_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      iconColor: '#3F9AF1',
      fontWeight: '300',
      fontFamily: 'Nunito',
      fontSize: '17px',
      letterSpacing: '-0.02em',
    },
    empty: {
      '::placeholder': {
        color: '#999999',
      },
    },
  },
}

export function CheckoutForm({
  orderDetails,
  product,
  quantity,
  onSuccess,
  setOrderId,
  onAddToOrdersHistory,
  onPrevious,
  shippingFlatRate,
}: Props) {
  const classes = useStyles()
  const [status, setStatus] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [loaderStatus, setLoaderStatus] = React.useState<number>(0)
  const [paymentHandler] = usePaymentHandler(setOrderId, setLoaderStatus)

  const stripe = useStripe()
  const elements = useElements()

  const handlePayment = useCallback(
    async input => {
      const { status, orderName } = await paymentHandler(input, stripe)

      if (status === stripeStatus.SUCCEEDED) {
        setStatus(PaymentStatus.SUCCESS)
        onAddToOrdersHistory({
          orderId: orderName,
          date: moment().format('D MMM YYYY / k:mm'),
          price: product.myShopPrice + shippingFlatRate,
          photo: product.photos[0],
          website: product.brand.website,
          supportEmail: product.variants[0]?.supportEmail,
          deliveryTime: product.webshop?.deliveryTime,
          privacyPolicyUrl: product.webshop?.privacyPolicyUrl,
          termsAndConditionsUrl: product.webshop?.termsAndConditionsUrl,
          brandName: product.brand?.name,
          returnPolicy: product.webshop?.returnPolicy,
        })
        onSuccess()
      } else {
        throw new Error('PAYMENT_NOT_SUCCESSFUL')
      }
    },
    [
      onAddToOrdersHistory,
      onSuccess,
      paymentHandler,
      product.brand,
      product.myShopPrice,
      product.photos,
      product.variants,
      product.webshop,
      shippingFlatRate,
      stripe,
    ],
  )

  const handleSubmit = async event => {
    event.preventDefault()
    GoogleAnalytics.track({
      category: 'Product',
      action: 'Payment (Initiate Payment)',
    })
    try {
      /** create payment method */
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      })

      if (error) {
        throw new Error('PAYMENT_ERROR')
      }

      setStatus(PaymentStatus.LOADING)

      /** create payment intent */
      const input = getCreatePaymentIntentInput(
        product,
        orderDetails,
        quantity,
        paymentMethod?.id,
        shippingFlatRate,
      )

      await handlePayment(input)
      GoogleAnalytics.track({
        category: 'Product',
        action: 'Payment Was Successful, Show Order Success!',
      })
    } catch (error) {
      console.error(error.code, error.message)
      GoogleAnalytics.track({
        category: 'Product',
        action: 'Payment Error (' + error.message + ')',
      })
      setErrorMessage(errorMessageFromCode(error.code, error.message))
      setStatus(PaymentStatus.ERROR)
    }
  }

  return (
    <>
      <div className={classes.center}>
        <div className={classes.paymentMethods} />
      </div>
      <div className={classes.checkout}>
        {status !== PaymentStatus.LOADING && (
          <>
            <CardElement options={CARD_OPTIONS} className={classes.card} />
            <Button
              className={classes.button}
              onClick={handleSubmit}
              disabled={!stripe || status === PaymentStatus.LOADING}
            >
              <LockIcon className={classes.lock} />
              Pay
            </Button>
          </>
        )}
        {status === PaymentStatus.LOADING && (
          <StepsLoader
            steps={[
              'Verifying payment',
              'Confirming stock level',
              `Placing order at <b>${product.brand.name}</b>`,
            ]}
            status={loaderStatus}
          />
        )}

        {status === PaymentStatus.ERROR && (
          <div className={classes.error}>{errorMessage}</div>
        )}
        {status !== PaymentStatus.LOADING && (
          <span className={classes.subTitle}>
            Billing Address is same as shipping -{' '}
            <span onClick={onPrevious} className={classes.changeBilling}>
              Change
            </span>
          </span>
        )}
      </div>
    </>
  )
}

export default CheckoutForm
