import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog/Dialog'
// import Slide from '@material-ui/core/Slide/Slide'
import cn from 'classnames'

type Props = {
  open: boolean
  onClose: () => void
  children: React.ReactElement
  className?: string
}

const useStyles = makeStyles(
  (theme: any) => ({
    root: {
      overflow: 'auto',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      boxShadow: 'none',
      background: '#FFFFFF',
      boxSizing: 'border-box',
      margin: 0,
      width: '95%',
      borderRadius: '15px',
      bottom: 0,
      [theme.breakpoints.up('xs')]: {
        margin: 'auto',
        borderRadius: '15px 15px 15px 15px',
        position: 'unset',
      },
      [theme.breakpoints.up('sm')]: {
        margin: 'auto',
        borderRadius: '15px 15px 15px 15px',
        position: 'unset',
      },
      minHeight: 200,
    },
  }),
  { index: 1 },
)

export function DialogContainer({
  open,
  onClose,
  children,
  className = '',
  ...others
}: Props) {
  const classes = useStyles()
  return (
    <Dialog
      className={classes.root}
      classes={{ paper: cn(classes.paper, className) }}
      open={open}
      keepMounted
      onClose={onClose}
      {...others}
    >
      {children}
    </Dialog>
  )
}

export default DialogContainer
