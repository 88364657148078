import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar/AppBar'
import Toolbar from '@material-ui/core/Toolbar/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton/IconButton'
import { Link } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog/Dialog'
import ReactCountryFlag from 'react-country-flag'
import { Button } from '@material-ui/core'

import { useGetIPInfo } from '../../hooks/useGetIPInfo'
import useGetCurrencyExchangeRate from '../../hooks/useGetCurrencyExchangeRate'
import SelectCountryCurrencyDialog from '../SelectCountryCurrencyDialog/SelectCountryCurrencyDialog'
import { Country, Currency } from '../../types'
import { getCurrencyByCode } from '../../lib/getCurrencyData'
import { getCountryByCode } from '../../lib/getCountryData'
import GoogleAnalytics from '../../lib/GoogleAnalytics'

type Props = {
  shopName?: string
  shopUrl?: string
  /** Save in store function  */
  setDataInStore?: (data: {
    country?: Country
    currency?: Currency
    exchangeRate?: number
    shipToSelectedCountry?: boolean
  }) => void
  /** Data **/
  country?: Country
  currency?: Currency
  shipToSelectedCountry?: boolean
}

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    color: theme.palette.standard,
    background: theme.palette.white,
    height: 48,
    minHeight: 'unset',
    [theme.breakpoints.up('sm')]: {
      minHeight: 60,
    },
  },
  logo: {
    width: 135,
    paddingLeft: 18,
    [theme.breakpoints.up('sm')]: {
      width: 180,
    },
    verticalAlign: 'middle',
    maxWidth: 'inherit',
    maxHeight: 'inherit',
  },
  menuButton: {},
  menuIcon: {
    fontSize: '2rem',
    color: '#303466',
  },
  appBar: {
    boxShadow: theme.styles.shadow,
  },
  menu: {
    maxWidth: 500,
    width: '90%',
    minHeight: 300,
    background: theme.palette.white,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.06)',
    borderRadius: 30,
    zIndex: 100,
  },
  menuItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 800,
    fontSize: 25,
    lineHeight: '34px',
    marginTop: 20,
  },
  shop: {
    fontFamily: 'Nunito',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '22px',
    paddingBottom: 20,
    textDecoration: 'none',
    color: '#000',
  },
  link: {
    fontFamily: 'Nunito',
    fontSize: 16,
    lineHeight: '22px',
    color: '#999999',
    paddingBottom: 20,
    textDecoration: 'none',
  },
  currency: {
    color: '#303466',
  },
}))

function Menu(props: {
  open: boolean
  onClose: () => void
  shopName: string
  shopUrl: string
  urlPrefix: string
}) {
  const classes = useStyles()

  const trackMenuNavigation = (link: string) => () => {
    GoogleAnalytics.track({
      category: 'Menu',
      action: `Navigate To ${link}`,
    })
  }

  const supportEmail = 'support@myshop.io'
  const supportSubject = 'MyShop Query'

  return (
    <Dialog
      classes={{ paper: classes.menu }}
      open={props.open}
      keepMounted
      onClose={props.onClose}
    >
      <div className={classes.menuItems}>
        <h3 className={classes.title}>Menu</h3>
        {props.shopName && props.shopUrl && (
          <Link
            onClick={props.onClose}
            to={`${props.urlPrefix}`}
            className={classes.shop}
          >
            {props.shopName}
          </Link>
        )}
        <Link
          className={classes.link}
          to={`${props.urlPrefix}/history`}
          onClick={trackMenuNavigation('History')}
        >
          My Orders
        </Link>
        <a
          className={classes.link}
          href="https://myshop.io"
          onClick={trackMenuNavigation('Get your own store')}
        >
          Get your own store
        </a>
        <Link
          to={`${props.urlPrefix}/faq`}
          className={classes.link}
          onClick={trackMenuNavigation('FAQ')}
        >
          FAQ
        </Link>
        <Link
          to={`${props.urlPrefix}/terms_and_conditions`}
          className={classes.link}
          onClick={trackMenuNavigation('Terms And Conditions')}
        >
          Terms & Conditions
        </Link>
        <Link
          to={`${props.urlPrefix}/privacy_policy`}
          className={classes.link}
          onClick={trackMenuNavigation('Privacy Policy')}
        >
          Privacy Policy
        </Link>
        <a
          href={`mailto:${supportEmail}?subject=${supportSubject}`}
          className={classes.link}
        >
          Contact support
        </a>
      </div>
    </Dialog>
  )
}

function Header({
  shopName = null,
  shopUrl = null,
  setDataInStore,
  country,
  currency,
  shipToSelectedCountry,
}: Props) {
  const classes = useStyles()
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [selectCurrencyIsOpen, setSelectCurrencyIsOpen] = useState(false)

  // IP information
  const [ipInfo] = useGetIPInfo()

  // Currency Exchange.
  const { rate } = useGetCurrencyExchangeRate({
    to: currency?.code,
  })

  useEffect(() => {
    if (!country && setDataInStore) {
      const countryObject = getCountryByCode(ipInfo.countryCode)
      setDataInStore({ country: countryObject })
    }
    if (!currency && setDataInStore) {
      const currencyObject = getCurrencyByCode(ipInfo.currency)
      setDataInStore({ currency: currencyObject })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setDataInStore &&
      setDataInStore({
        exchangeRate: rate,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rate])

  const handleChangeCurrency = () => {
    GoogleAnalytics.track({
      category: 'Currency',
      action: 'Open Select Currency Dialog',
    })
    setSelectCurrencyIsOpen(true)
  }

  const handleSelectCountryCurrencyClose = () => {
    GoogleAnalytics.track({
      category: 'Currency',
      action: 'Close Select Currency Dialog',
    })
    setSelectCurrencyIsOpen(false)
  }

  const handleSelectCountryCurrency = value => {
    const country = value?.country
    const currency = value?.currency
    const shipToSelectedCountry = value?.shipToSelectedCountry
    // Currency Exchange.
    setDataInStore({ country, currency, shipToSelectedCountry })
    setSelectCurrencyIsOpen(false)
    GoogleAnalytics.track({
      category: 'Currency',
      action: 'Change Currency',
    })
  }
  // End Select country functions.

  const toggleMenu = () => {
    if (menuIsOpen) {
      GoogleAnalytics.track({
        category: 'Menu',
        action: 'Close Menu Dialog',
      })
    } else {
      GoogleAnalytics.track({
        category: 'Menu',
        action: 'Open Menu Dialog',
      })
    }
    setMenuIsOpen(!menuIsOpen)
  }

  const urlPrefix = shopUrl ? `/${shopUrl}` : ''

  return (
    <div className={classes.container}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.header}>
          <Link to={urlPrefix}>
            <img
              alt="my-shop-logo"
              className={classes.logo}
              src="/static/images/logo-main.png"
            />
          </Link>
          <div>
            {country && country?.code ? (
              <Button
                onClick={handleChangeCurrency}
                data-testid="change-currency"
              >
                <ReactCountryFlag
                  styleprops={{
                    width: '20px',
                    height: '20px',
                  }}
                  countryCode={country?.code}
                  svg
                />
                &nbsp; &nbsp;
                <span className={classes.currency}>{currency?.code}</span>
              </Button>
            ) : null}

            <IconButton
              onClick={toggleMenu}
              edge="end"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              {menuIsOpen ? (
                <CloseIcon className={classes.menuIcon} />
              ) : (
                <MenuIcon className={classes.menuIcon} />
              )}
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Menu
        open={menuIsOpen}
        onClose={toggleMenu}
        shopName={shopName}
        shopUrl={shopUrl}
        urlPrefix={urlPrefix}
      />

      <SelectCountryCurrencyDialog
        open={selectCurrencyIsOpen}
        onClose={handleSelectCountryCurrencyClose}
        onSelect={handleSelectCountryCurrency}
        defaultCountryCode={country?.code}
        shipToSelectedCountry={shipToSelectedCountry}
      />
    </div>
  )
}

export default Header
