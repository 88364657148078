import React, { Fragment } from 'react'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  GridItemsAlignment,
  GridJustification,
  Hidden,
} from '@material-ui/core'
import { TextDialog } from '../TextDialog/TextDialog'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import GoogleAnalytics from '../../lib/GoogleAnalytics'
const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    height: '100%',
  },
  container: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-around',
    width: '100%',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    margin: 7,
    width: 15,
    height: 15,
    objectFit: 'contain',
  },
  blinkIcon: {
    marginLeft: 0,
    color: 'rgba(0, 0, 0, 0.6)',
  },
  text: {
    fontSize: 10,
    lineHeight: '14px',
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.4)',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    [theme.breakpoints.up('sm')]: {
      fontSize: 12,
    },
  },
  blinkText: {
    color: 'rgba(0, 0, 0, 0.6)!important',
    fontSize: '12px!important',
    fontWeight: 'bold',
  },
  modalItem: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    flexWrap: 'nowrap',
  },
  modalContainer: {
    padding: theme.spacing(1, 3),
    width: '100%',
    display: 'block',
  },
  modalTitle: {
    color: '#303466',
  },
  modalListItem: {
    display: 'flex',
  },
  blinkListItem: {
    justifyContent: 'flex-start',
  },
  modalIcon: {
    width: 34,
    paddingLeft: 4,
    paddingRight: 4,
  },
  modalSubTitle: {
    color: '#303466',
    fontSize: 19,
    paddingLeft: 10,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    // line-height: 26px;
  },
  modalText: {
    color: '#303466',
    fontSize: 14,
    marginTop: 6,
    marginBottom: 9,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    // line-height: 19px;
    letterSpacing: '-0.02em',
  },
  boldLink: {
    color: '#303466',
    fontWeight: 700,
  },
  background: {
    background: theme.palette.background.default,
  },
  swiperContainer: {
    maxWidth: '134px',
    position: 'relative',
  },
  swiper: {
    position: 'relative',
    width: '100%',
    zIndex: 1,
    display: 'flex',
    transitionProperty: 'transform',
    boxSizing: 'content-box',
  },
  onlyDesktop: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}))

type SellingPointsProps = {
  blink?: boolean
  className?: string
  backgroundColor?: string
  alignItems?: GridItemsAlignment
  itemsJustify?: GridJustification
  lastItemJustify?: GridJustification
}

function SellingPoint({
  onClick,
  text,
  icon,
  link,
  blink,
}: {
  onClick: () => void
  text: string
  link?: string
  icon: string
  blink?: boolean
}) {
  const classes = useStyles()
  return (
    <div className={cn(classes.listItem, blink && classes.blinkListItem)}>
      <img
        alt="point-icon"
        className={cn(classes.icon, blink && classes.blinkIcon)}
        src={`/static/icons/${icon}`}
      />
      {link ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={cn(classes.text, blink && classes.blinkText)}
          onClick={onClick}
        >
          {text}
        </a>
      ) : (
        <span
          className={cn(classes.text, blink && classes.blinkText)}
          onClick={onClick}
        >
          {text}
        </span>
      )}
    </div>
  )
}

const SellingPoints = (props: SellingPointsProps) => {
  const classes = useStyles(props)
  const [open, setOpen] = React.useState(false)
  const title = 'Why MyShop?'
  const content = ''

  const trackSetupYourOwnMyShop = () => {
    GoogleAnalytics.track({
      category: 'Selling Points (Why MyShop?)',
      action: 'Set up your own MyShop (go to https://myshop.io)',
    })
  }

  const Body = () => (
    <Grid
      className={classes.modalContainer}
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
      spacing={3}
    >
      <Grid container direction="column" item xs className={classes.modalItem}>
        <Grid className={classes.listItem} item>
          <img
            className={classes.modalIcon}
            src="/static/icons/free-shipping-color-icon.svg"
            alt="icon"
          />
          <span className={classes.modalSubTitle}>Always Free Shipping</span>
        </Grid>
        <Grid item>
          <p className={classes.modalText}>
            All orders from MyShop gives you free standard shipping.
          </p>
        </Grid>
      </Grid>
      <Grid container direction="column" xs item className={classes.modalItem}>
        <Grid className={classes.listItem} item>
          <img
            className={classes.modalIcon}
            src="/static/icons/discounts-color-icon.svg"
            alt="icon"
          />
          <span className={classes.modalSubTitle}>Always Discounts</span>
        </Grid>
        <Grid item>
          <p className={classes.modalText}>
            All products are disounted compared to the brands regular webshop
            prices.
          </p>
        </Grid>
      </Grid>
      <Grid container direction="column" xs item className={classes.modalItem}>
        <Grid className={classes.listItem} item>
          <img
            className={classes.modalIcon}
            src="/static/icons/ships-from-brands-color-icon.svg"
            alt="icon"
          />
          <span className={classes.modalSubTitle}>Ships From Brands</span>
        </Grid>
        <Grid item>
          <p className={classes.modalText}>
            All orders ship directly from the brands warehouse. Order
            confirmation e-mail sent from brands upon order placement.
          </p>
        </Grid>
      </Grid>
      <Grid container direction="column" xs item className={classes.modalItem}>
        <Grid className={classes.listItem} item>
          <img
            className={classes.modalIcon}
            src="/static/icons/build-your-shop-color-icon.svg"
            alt="icon"
          />
          <span className={classes.modalSubTitle}>Set up your own MyShop</span>
        </Grid>
        <Grid item>
          <p className={classes.modalText}>
            Click{' '}
            <a
              href="https://myshop.io"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.boldLink}
              onClick={trackSetupYourOwnMyShop}
            >
              here
            </a>{' '}
            to set up your own MyShop. You need to download the Brandbassador
            app.
          </p>
        </Grid>
      </Grid>
    </Grid>
  )

  const handleClose = () => {
    setOpen(false)
  }

  const handleItemClick = () => {
    setOpen(true)
    GoogleAnalytics.track({
      category: 'Selling Points (Why MyShop?)',
      action: 'Open (Why MyShop?) Details Dialog',
    })
  }

  const params = {
    spaceBetween: 30,
    centeredSlides: true,
    slidesPerView: 1,
    effect: 'fade',
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
    },
  }

  if (props?.blink)
    return (
      <div className={classes.onlyDesktop}>
        <Swiper
          containerClass={classes.swiperContainer}
          wrapperClass={classes.swiper}
          {...params}
        >
          <div className={classes.background}>
            <SellingPoint
              blink
              text="Free Shipping"
              onClick={handleItemClick}
              icon="free-shipping-icon.svg"
            />
          </div>
          <div className={classes.background}>
            <SellingPoint
              blink
              text="Exclusive Discounts"
              onClick={handleItemClick}
              icon="exclusive-discount.svg"
            />
          </div>
          <div className={classes.background}>
            <SellingPoint
              blink
              text="Ships from Brand"
              onClick={handleItemClick}
              icon="shipping-from-brand-icon.svg"
            />
          </div>
          <div className={classes.background}>
            <SellingPoint
              blink
              text="Build your MyShop"
              onClick={trackSetupYourOwnMyShop}
              icon="build-your-shop-icon.svg"
              link="https://myshop.io"
            />
          </div>
        </Swiper>
        <TextDialog
          title={title}
          open={open}
          content={content}
          onClose={handleClose}
        >
          <Body />
        </TextDialog>
      </div>
    )
  else
    return (
      <Fragment>
        <div className={cn(classes.root, props?.className)}>
          <div className={classes.container}>
            <SellingPoint
              text="Free Shipping"
              onClick={handleItemClick}
              icon="free-shipping-icon.svg"
            />
            <SellingPoint
              text="Exclusive Discounts"
              onClick={handleItemClick}
              icon="exclusive-discount.svg"
            />
            <SellingPoint
              text="Ships from Brand"
              onClick={handleItemClick}
              icon="shipping-from-brand-icon.svg"
            />
            <Hidden smDown implementation="css">
              <SellingPoint
                text="Build your MyShop"
                onClick={trackSetupYourOwnMyShop}
                icon="build-your-shop-icon.svg"
                link="https://myshop.io"
              />
            </Hidden>
          </div>
        </div>

        <TextDialog
          title={title}
          open={open}
          content={content}
          onClose={handleClose}
        >
          <Body />
        </TextDialog>
      </Fragment>
    )
}

export default SellingPoints
