import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List } from '@material-ui/core'

import DialogContainer from '../DialogContainer/DialogContainer'
import ShippingDialog from '../ShippingDialog/ShippingDialog'
import { Steps } from '../../Constants'
import VariantsDialog from '../VariantsDialog/VariantsDialog'
import DetailsAddressDialog from '../DetailsAddressDialog/DetailsAddressDialog'
import PaymentDialog from '../PaymentDialog/PaymentDialog'
import SuccessDialog from '../SuccessDialog/SuccessDialog'
import { Address, Variant, Product, Currency, IPInfo } from '../../types'
import TermsAndPoliciesDialog from '../TermsAndPoliciesDialog'
import PaymentMethode from '../PaymentMethode'
import {
  IPLocationInShippingCounties,
  normalizeShortUrl,
} from '../../lib/utils'

type Props = {
  open: boolean
  onClose: () => void
  step: number
  shopReference: string
  countries: string[]
  variants: Variant[]
  onPrevious: () => void
  shippingDetails: Address
  billingDetails: Address
  orderDetails?: {
    variant: Variant
    shippingDetails: Address
    billingDetails: Address
    buyerAcceptsMarketing: boolean
  }
  product: Product
  quantity: number
  onContinueShippingDialog: (...args: any[]) => void
  handleContinuePaymentMethode: (...args: any[]) => void
  onContinueProductFeaturesDialog: (...args: any[]) => void
  onContinueShippingAddressDialog: (...args: any[]) => void
  onContinueBillingDetailsDialog: (...args: any[]) => void
  onSuccess: () => void
  setOrderId: (arg: string) => void
  orderId: string
  supportEmail: string
  onTermsAndConditions: (...args: any[]) => void
  onTermsAndConditionsPrevious: (...args: any[]) => void
  onPaymentDialogPrevious: (...args: any[]) => void
  shopUrl: string
  onBuyerAcceptsMarketing: (...args: any[]) => void
  onAddToOrdersHistory: any
  ipInfo: IPInfo
  /* To convert currency */
  exchangeRate?: number
  currency?: Currency
  shippingFlatRate?: number
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  menuButton: {
    position: 'absolute',
    margin: '5px',
    marginTop: '10px',
    right: '5px',
    color: 'rgba(0,0,0,0.2)!important',
    width: 30,
    height: 30,
    zIndex: 1,
  },
  menuIcon: {
    fontSize: '2rem',
    width: 25,
    height: 25,
  },
  list: {
    width: '100%',
  },
}))

export function CheckoutDialog({
  open,
  onClose,
  step,
  countries,
  variants,
  onPrevious,
  shippingDetails,
  billingDetails,
  orderDetails,
  product,
  quantity,
  shopReference,
  onContinueShippingDialog,
  handleContinuePaymentMethode,
  onContinueProductFeaturesDialog,
  onContinueShippingAddressDialog,
  onContinueBillingDetailsDialog,
  onSuccess,
  setOrderId,
  orderId,
  supportEmail,
  onTermsAndConditions,
  onTermsAndConditionsPrevious,
  shopUrl,
  onPaymentDialogPrevious,
  onBuyerAcceptsMarketing,
  onAddToOrdersHistory,
  ipInfo,
  exchangeRate,
  currency,
  shippingFlatRate,
}: Props) {
  const classes = useStyles()
  const [isTermsAndConditions, setTermsAndConditions] = useState(false)
  const [isBillingAddressTheSame, setBillingAddressTheSame] = useState(true)
  return (
    <DialogContainer open={open} onClose={onClose} aria-label="checkout-dialog">
      <List className={classes.list}>
        <div className={classes.root}>
          {step === Steps.SHIPPING_COUNTRIES && (
            <ShippingDialog
              countries={countries}
              onContinue={onContinueShippingDialog}
              shippingCountry={shippingDetails?.country}
              province={shippingDetails?.province}
              onClose={onClose}
              ipLocation={
                IPLocationInShippingCounties(
                  ipInfo.country,
                  product.shippingCountries,
                )
                  ? ipInfo?.country
                  : ''
              }
              brandName={product?.brand?.name}
              brandShortUrl={normalizeShortUrl(
                product?.brand?.name,
                product?.webshop.displayURL || product?.brand?.website,
              )}
            />
          )}

          {step === Steps.PRODUCT_VARIANTS && (
            <VariantsDialog
              variants={variants}
              onContinue={onContinueProductFeaturesDialog}
              onPrevious={onPrevious}
              onClose={onClose}
              quantity={quantity}
              shippingFlatRate={shippingFlatRate}
              brandName={product.brand.name}
              brandShortUrl={normalizeShortUrl(
                product?.brand?.name,
                product?.webshop.displayURL || product?.brand?.website,
              )}
              exchangeRate={exchangeRate}
              currency={currency}
            />
          )}

          {step === Steps.PAYMENT_METHODE && (
            <PaymentMethode
              orderDetails={orderDetails}
              product={product}
              quantity={quantity}
              onSuccess={onSuccess}
              onPrevious={onPrevious}
              setOrderId={setOrderId}
              onClose={onClose}
              onAddToOrdersHistory={onAddToOrdersHistory}
              shippingFlatRate={shippingFlatRate}
              onContinue={handleContinuePaymentMethode}
              shippingCountry={shippingDetails?.country}
            />
          )}

          {step === Steps.SHIPPING_ADDRESS && (
            <DetailsAddressDialog
              title="Shipping Address"
              onSubmit={onContinueShippingAddressDialog}
              initState={shippingDetails}
              onPrevious={onPrevious}
              displayBillingCheckbox
              onTermsAndConditions={onTermsAndConditions}
              isTermsAndConditions={isTermsAndConditions}
              setTermsAndConditions={setTermsAndConditions}
              isBillingAddressTheSame={isBillingAddressTheSame}
              setBillingAddressTheSame={setBillingAddressTheSame}
              onClose={onClose}
            />
          )}

          {step === Steps.BILLING_ADDRESS && (
            <DetailsAddressDialog
              title="Billing Details"
              onSubmit={onContinueBillingDetailsDialog}
              initState={billingDetails}
              onPrevious={onPrevious}
              onClose={onClose}
            />
          )}

          {step === Steps.TERMS_AND_POLICIES && (
            <TermsAndPoliciesDialog
              title="Terms and Policies"
              onPrevious={onTermsAndConditionsPrevious}
              brandName={product.brand.name}
              privacyPolicyUrl={product.webshop.privacyPolicyUrl}
              termsAndConditionsUrl={product.webshop.termsAndConditionsUrl}
              shopUrl={shopUrl}
              buyerAcceptsMarketing={orderDetails?.buyerAcceptsMarketing}
              onBuyerAcceptsMarketing={onBuyerAcceptsMarketing}
              onClose={onClose}
            />
          )}

          {step === Steps.PAYMENT && (
            <PaymentDialog
              orderDetails={orderDetails}
              product={product}
              quantity={quantity}
              onSuccess={onSuccess}
              onPrevious={() =>
                onPaymentDialogPrevious(isBillingAddressTheSame)
              }
              setOrderId={setOrderId}
              onClose={onClose}
              onAddToOrdersHistory={onAddToOrdersHistory}
              exchangeRate={exchangeRate}
              currency={currency}
              shippingFlatRate={shippingFlatRate}
            />
          )}
          {step === Steps.SUCCESS && (
            <SuccessDialog
              brandName={product?.brand?.name}
              onClose={onClose}
              orderId={orderId}
              shopReference={shopReference}
              support={supportEmail || product?.brand?.name}
              brandWebsite={
                product?.webshop.displayURL || product?.brand?.website
              }
            />
          )}
        </div>
      </List>
    </DialogContainer>
  )
}

export default CheckoutDialog
