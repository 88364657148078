import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField/TextField'
import Button from '@material-ui/core/Button/Button'
import { Formik } from 'formik'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import ReactCountryFlag from 'react-country-flag'
import { getCountryName, getProvinces } from '../../lib/getCountryData'
import { object, string } from 'yup'
import LockIcon from '@material-ui/icons/Lock'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import Title from '../Title/Title'
import { Country } from '../../types'
import { sortCountries } from '../../lib/utils'

type Props = {
  /** countries of the shipping */
  countries: string[]
  onContinue: (args: any) => void
  shippingCountry?: string
  province?: string
  onClose: () => void
  ipLocation: string
  brandName?: string
  brandShortUrl?: string
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 500,
  },
  title: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 25,
    lineHeight: '34px',
    margin: 30,
    color: '#000',
    marginTop: 30,
    marginBottom: 5,
  },
  subTitle: {
    fontFamily: 'Nunito',
    fontSize: 12,
    lineHeight: '16px',
    color: '#999999',
    letterSpacing: '-0.02em',
    marginBottom: 10,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 25,
    paddingBottom: 23,
    paddingTop: 10,
  },
  input: {
    marginBottom: 15,
    marginTop: 5,
    minWidth: 250,
  },
  rootStyle: {
    height: 50,
    borderRadius: 10,
    background: '#9a9a9a08',
  },
  labelStyle: {
    top: -1,
    fontFamily: 'Nunito',
    fontSize: 16,
    letterSpacing: '-0.02em',
    color: '#999999',
  },
  item: {
    height: 30,
  },
  selectStyle: {
    '&::focus': {
      borderRadius: 10,
    },
  },
  button: {
    ...theme.styles.button,
    height: 50,
    marginTop: 5,
    ...theme.styles.blueButton,
  },
  secureConnectionWrapper: {
    paddingBottom: 16,
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    flexFlow: 'column nowrap',
  },
  brandName: {
    fontSize: 14,
    fontWeight: 800,
    fontFamily: 'Helvetica',
    lineHeight: '10px',
    paddingTop: 5,
    paddingBottom: 8,
    margin: 0,
    display: 'block',
    color: 'black',
  },
  secureIcon: {
    fontSize: 13,
    lineHeight: '13.8px',
    marginBottom: -2,
    paddingRight: 3,
  },
  checkIcon: {
    fontSize: 14,
    lineHeight: '13.8px',
    marginBottom: -3,
    paddingLeft: 2,
    color: theme.palette.blue,
  },
  secureConnection: {
    fontSize: 12,
    fontStyle: 'italic',
    color: theme.palette.gray,
    fontFamily: 'Helvetica',
    lineHeight: '13.8px',
  },
}))

const validationSchema = object({
  country: string().required('Required'),
})

export function ShippingDialog({
  countries,
  onContinue,
  shippingCountry = '',
  province = '',
  onClose,
  ipLocation,
  brandName,
  brandShortUrl,
}: Props) {
  const classes = useStyles()

  const countriesSorted: Array<Country> = countries
    .map((countryCode: string) => ({
      code: countryCode,
      name: getCountryName(countryCode),
    }))
    .sort(sortCountries)

  return (
    <div className={classes.root}>
      <Title title="Shipping to?" onClose={onClose} />
      <span className={classes.subTitle}>
        This product only ships to the following countries
      </span>

      <Formik
        initialValues={{
          country: shippingCountry || ipLocation,
          province: province,
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
          onContinue(values)
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          errors,
        }: any) => (
          <div className={classes.container}>
            <TextField
              id="country-select"
              classes={{ root: classes.rootStyle }}
              className={classes.input}
              variant="outlined"
              label="Country"
              name="country"
              InputProps={{ classes: { root: classes.rootStyle } }}
              InputLabelProps={{ classes: { root: classes.labelStyle } }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.country}
              error={touched.country && !!errors.country}
              select
              SelectProps={{ classes: { root: classes.selectStyle } }}
            >
              {countriesSorted.map(({ code, name }: Country) => (
                <MenuItem
                  key={code}
                  value={code}
                  className={classes.item}
                  data-testid={code}
                >
                  <ReactCountryFlag
                    styleprops={{
                      width: '20px',
                      height: '20px',
                    }}
                    countryCode={code}
                    svg
                  />
                  &nbsp; &nbsp;
                  {name}
                </MenuItem>
              ))}
            </TextField>

            {getProvinces(values.country).length > 0 && (
              <TextField
                id="province-select"
                classes={{ root: classes.rootStyle }}
                className={classes.input}
                variant="outlined"
                label="State/Province"
                name="province"
                InputProps={{ classes: { root: classes.rootStyle } }}
                InputLabelProps={{ classes: { root: classes.labelStyle } }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.province}
                error={touched.province && !!errors.province}
                select
                SelectProps={{ classes: { root: classes.selectStyle } }}
              >
                {getProvinces(values.country).map(province => (
                  <MenuItem
                    key={province}
                    value={province}
                    className={classes.item}
                  >
                    {province}
                  </MenuItem>
                ))}
              </TextField>
            )}

            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </div>
        )}
      </Formik>
      <div className={classes.secureConnectionWrapper}>
        <p className={classes.brandName}>
          {brandShortUrl}
          <CheckCircleIcon fontSize="small" className={classes.checkIcon} />
        </p>
        <span className={classes.secureConnection}>
          <LockIcon fontSize="small" className={classes.secureIcon} />
          Items are sold and shipped by {brandName}
        </span>
      </div>
    </div>
  )
}

export default ShippingDialog
