import React, { useState } from 'react'
import {
  makeStyles,
  Link,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Divider,
  ButtonBase,
  Grid,
} from '@material-ui/core'
import ReturnPolicy from '../ReturnPolicy'
import cn from 'classnames'

type Props = {
  image?: string
  price?: string
  url?: string
  date?: string
  orderNumber?: number
  deliveryTime: string
  supportEmail?: string
  privacyPolicyUrl?: string
  termsAndConditionsUrl?: string
  brandName?: string
  returnPolicy?: string
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  accordion: {
    marginTop: 0,
    marginBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    background: '#FFFFFF',
    boxShadow: '0px 3px 7px rgba(41, 57, 109, 0.0622)',
    borderRadius: 7,
  },
  accordionSummary: {
    paddingLeft: 0,
    paddingRight: 0,
    '& > div': {
      marginTop: '0!important',
      marginBottom: '0!important',
    },
  },
  accordionDetails: {
    padding: '21px 25px 25px !important',
  },
  productImageContainer: {
    width: 81,
    height: 81,
  },
  productImage: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  productImagePlaceholer: {
    margin: 'auto',
    display: 'block',
    maxWidth: '50%',
    maxHeight: '50%',
    filter: 'brightness(0) opacity(0.35)',
  },
  urlContainer: {
    maxWidth: 200,
  },
  url: {
    fontSize: 14,
    fontWeight: 700,
    color: '#393939 !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1 /* number of lines to show */,
    '-webkit-box-orient': 'vertical',
  },
  text: {
    fontSize: 12,
    fontWeight: 400,
  },
  priceContainer: {
    paddingTop: '11px!important',
    paddingBottom: '10px!important',
  },
  price: {
    fontSize: 16,
    fontWeight: 700,
    paddingTop: 6,
  },
  showMessage: {
    fontSize: 11,
    paddingBottom: 11,
    textDecoration: 'underline',
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
})

const OrderCard = ({
  image,
  price,
  url,
  date,
  orderNumber,
  deliveryTime,
  supportEmail,
  termsAndConditionsUrl,
  privacyPolicyUrl,
  brandName,
  returnPolicy,
}: Props) => {
  const classes = useStyles()

  const [showMessage, setShowMessage] = useState('show more')

  const onAccordionCollapseStateChange = (event, expanded) => {
    if (expanded) setShowMessage('show less')
    else setShowMessage('show more')
  }
  const [returnPolicyIsOpen, setReturnPolicyIsOpen] = useState(false)
  const toggleReturnPolicy = event => {
    event.preventDefault()
    setReturnPolicyIsOpen(!returnPolicyIsOpen)
  }

  return (
    <div className={classes.root}>
      <ReturnPolicy
        brandName={brandName}
        content={returnPolicy}
        open={returnPolicyIsOpen}
        onClose={toggleReturnPolicy}
      />
      <Accordion
        className={classes.accordion}
        onChange={onAccordionCollapseStateChange}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={null}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item>
              <ButtonBase className={classes.productImageContainer}>
                <img
                  className={
                    !image
                      ? classes.productImagePlaceholer
                      : classes.productImage
                  }
                  alt="complex"
                  src={!image ? '/static/images/empty-preview.png' : image}
                />
                <Divider orientation="vertical" flexItem />
              </ButtonBase>
            </Grid>
            <Grid
              item
              container
              xs={6}
              spacing={4}
              direction="column"
              justify="center"
              alignItems="flex-start"
            >
              <Typography className={classes.urlContainer}>
                <Link className={classes.url} href={url} underline="always">
                  {url}
                </Link>
              </Typography>
              <Typography className={classes.text} color="textSecondary">
                {date}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              container
              direction="column"
              justify="space-between"
              alignItems="center"
              // spacing={2}
              className={classes.priceContainer}
            >
              <Grid item xs>
                <Typography className={classes.price} color="primary">
                  ${parseFloat(price || '0').toFixed(2)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.showMessage}
                  variant="body2"
                  color="textPrimary"
                >
                  {showMessage}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.accordionDetails}>
          <Grid container justify="space-between" spacing={1}>
            <Grid item xs={8}>
              <Typography className={classes.text} color="textPrimary">
                Order#: {orderNumber}
              </Typography>
              <Typography className={classes.text} color="textPrimary">
                Delivery: {deliveryTime}
              </Typography>
              <Typography className={classes.text} color="textPrimary">
                Support: {supportEmail}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {privacyPolicyUrl && (
                <Typography className={classes.text} color="textPrimary">
                  <Link
                    href={privacyPolicyUrl}
                    color="textPrimary"
                    underline="always"
                    target="_blank"
                  >
                    Privacy policy
                  </Link>
                </Typography>
              )}
              {termsAndConditionsUrl && (
                <Typography className={classes.text} color="textPrimary">
                  <Link
                    href={termsAndConditionsUrl}
                    color="textPrimary"
                    underline="always"
                    target="_blank"
                  >
                    Term & condition
                  </Link>
                </Typography>
              )}
              {returnPolicy && (
                <Typography
                  className={cn(classes.text, classes.link)}
                  color="textPrimary"
                  onClick={toggleReturnPolicy}
                >
                  Return policy
                </Typography>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
      </Accordion>
    </div>
  )
}

export default OrderCard
