import { gql } from 'apollo-boost'

export const GetShopProductsByUrl = gql`
  query shopByUrl(
    $url: String!
    $countryCode: String
    $countAsView: Boolean
    $limit: Int
    $offset: Int
  ) {
    shopByUrl(countryCode: $countryCode, url: $url, countAsView: $countAsView) {
      id: userId
      logo: image
      shopName: name
      shopBio: bio
      salesCount: salesCount
      productsCount
      likes
      url
      views
      achievements {
        title
        description
        image
        emoji
      }
      productConnection(limit: $limit, offset: $offset) {
        edges {
          node {
            id
            name: title
            webShopPrice: priceUSD
            myShopPrice
            likes
            views
            photos
            discountPercentage
            shippingCountries
            description
            customDescription
            youtubeUrl
            variants {
              productId
              variantId
              price: priceUSD
              option1
              option2
              option3
              shippingCountries
              discountPercentage
              supportEmail
              stripeAccount: stripeConnectId
              stock
              ignoreStock
              published
            }
            webshop {
              docId
              deliveryTime
              shippingCountries
              privacyPolicyUrl
              termsAndConditionsUrl
              returnPolicy
              warehouseCountries
              allowShippingCostForSpecificMarkets
              flatRateShippingCountries
              shippingFlatRate
              freeShippingOnOrdersOver
              webshopURL
              displayURL
            }
            brand {
              brandId
              name
              website
              logo
              myShopPaymentAccount
              commissionsBalance
            }
          }
          cursor
        }
        pageInfo {
          totalCount
          hasNextPage
        }
      }
    }
  }
`

export const GetShopByUrl = gql`
  query shopByUrl($url: String!) {
    shopByUrl(url: $url) {
      id: userId
      logo: image
      shopName: name
      shopBio: bio
      salesCount: salesCount
      productsCount
      likes
      url
    }
  }
`
