import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs/Tabs'
import Tab from '@material-ui/core/Tab/Tab'
import ListIcon from '@material-ui/icons/List'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import InfiniteScroll from 'react-infinite-scroller'
import cn from 'classnames'

import { GridView } from './GridView'
import { FeedView } from './FeedView'
import { Currency, Product } from '../../types'
import GoogleAnalytics from '../../lib/GoogleAnalytics'

type Props = {
  products: Product[]
  shopReference: string
  getQuantity: (...arg: any[]) => number
  onOpenQuantityDialog: (...arg: any[]) => void
  onBuy: (...arg: any[]) => any
  loadMore: (...arg: any[]) => void
  hasMore: boolean
  onLike: (productId: string) => void
  isProductLiked: (productId: string) => boolean
  onPreview?: (...arg: any[]) => void
  preview?: Product | undefined
  previewIndex?: number | undefined

  exchangeRate?: number
  currency?: Currency
  getShippingFlatRate?: (productId: string) => number
  onCheckedBrands?: (brandName: string) => void
  checkedBrands?: any
  userId?: string

  selectedCountry?: string
}

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: theme.palette.background.default,
    maxWidth: 800,
    margin: 'auto',
    width: '100%',
  },
  tabs: {
    width: '100%',
    background: '#FAFAFA',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderLeft: 'none',
    borderRight: 'none',
  },
  tab: {
    fontSize: 32,
    opacity: 0.5,
  },
  unActive: {
    color: 'rgba(0,0,0,0.24)',
    opacity: 0.75,
  },
}))

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: any
  value: any
}

export function ProductsGrid({
  products,
  shopReference,
  getQuantity,
  onOpenQuantityDialog,
  onBuy,
  loadMore,
  hasMore,
  isProductLiked,
  onLike,
  exchangeRate,
  currency,
  preview,
  previewIndex,
  onPreview,
  getShippingFlatRate,
  onCheckedBrands,
  checkedBrands,
  userId,
  selectedCountry,
}: Props) {
  const classes = useStyles()
  const [tab, setTab] = useState(0)

  const handleTabChange = (event, selectedTab) => {
    GoogleAnalytics.track({
      category: 'Product',
      action: `Change Products Listing to ${
        selectedTab === 0 ? 'Grid View' : 'Feed View'
      }`,
    })
    setTab(selectedTab)
  }

  /**
   * Swiper does not load correctly if the component is not visible.
   * as a HACK, keep the feed view on under the grid, and set its opacity to 0
   * for 300ms (time to load the swiper) then set hidden as true and hide it.
   *  */
  // TODO: Replace Swiper and remove this hack

  const [productsLength, setProductsLength] = useState(0)

  useEffect(() => {
    if (products?.length !== productsLength) {
      setTimeout(() => {
        setProductsLength(products?.length)
      }, 100)
    }
  }, [productsLength, products])

  return (
    <div className={classes.container}>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        variant="fullWidth"
        indicatorColor="secondary"
        TabIndicatorProps={{ style: { opacity: 0.4 } }}
        textColor="secondary"
        aria-label="tabs"
        className={classes.tabs}
      >
        <Tab
          icon={
            <ViewModuleIcon
              className={cn(classes.tab, tab === 1 ? classes.unActive : '')}
            />
          }
          aria-label="grid"
        />
        <Tab
          icon={
            <ListIcon
              className={cn(classes.tab, tab === 0 ? classes.unActive : '')}
            />
          }
          aria-label="feed"
        />
      </Tabs>

      <InfiniteScroll pageStart={0} loadMore={loadMore} hasMore={hasMore}>
        <div hidden={tab !== 0}>
          <GridView
            products={products}
            shopReference={shopReference}
            getQuantity={getQuantity}
            onOpenQuantityDialog={onOpenQuantityDialog}
            onBuy={onBuy}
            onLike={onLike}
            isProductLiked={isProductLiked}
            preview={preview}
            previewIndex={previewIndex}
            onPreview={onPreview}
            exchangeRate={exchangeRate}
            currency={currency}
            getShippingFlatRate={getShippingFlatRate}
            onCheckedBrands={onCheckedBrands}
            checkedBrands={checkedBrands}
            userId={userId}
            selectedCountry={selectedCountry}
          />
        </div>

        <div
          hidden={products?.length === productsLength ? tab !== 1 : false}
          style={products?.length !== productsLength ? { opacity: 0 } : {}}
        >
          <FeedView
            products={products}
            shopReference={shopReference}
            getQuantity={getQuantity}
            onOpenQuantityDialog={onOpenQuantityDialog}
            onBuy={onBuy}
            onLike={onLike}
            isProductLiked={isProductLiked}
            exchangeRate={exchangeRate}
            currency={currency}
            getShippingFlatRate={getShippingFlatRate}
            userId={userId}
            selectedCountry={selectedCountry}
          />
        </div>
      </InfiniteScroll>
    </div>
  )
}

export default ProductsGrid
