import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useStripe, PaymentRequestButtonElement } from '@stripe/react-stripe-js'
import {
  makeStyles,
  Typography,
  Box,
  CircularProgress,
} from '@material-ui/core'
import { Address, Product, Variant } from '../../types'
import {
  errorMessageFromCode,
  getCreatePaymentIntentInput,
} from '../../lib/utils'
import { PaymentStatus, stripeStatus } from '../../Constants'
import moment from 'moment'
import usePaymentHandler from '../../hooks/usePaymentHandler'

const useStyles = makeStyles(
  () => ({
    container: {
      marginTop: 23,
    },
    note: {
      fontFamily: 'Nunito',
      fontSize: 12,
      lineHeight: '16px',
      color: '#999999',
      letterSpacing: '-0.02em',
      marginBottom: 16,
      textAlign: 'center',
      paddingLeft: 4,
      paddingRight: 8,
    },
    circularProgress: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 4,
      marginBottom: 4,
    },
  }),
  { index: 1 },
)

const useOptions = paymentRequest => {
  const options = useMemo(
    () => ({
      paymentRequest,
      style: {
        paymentRequestButton: {
          theme: 'dark',
          height: '50px',
          type: 'default',
        },
      },
    }),
    [paymentRequest],
  )

  return options
}

const usePaymentRequest = ({ options, onPaymentMethod }) => {
  const stripe = useStripe()
  const [paymentRequest, setPaymentRequest] = useState(null)
  const [canMakePayment, setCanMakePayment] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (stripe && paymentRequest === null) {
      const pr = stripe.paymentRequest(options)
      setPaymentRequest(pr)
    }
  }, [stripe, options, paymentRequest])

  useEffect(() => {
    let subscribed = true
    if (paymentRequest) {
      paymentRequest
        .canMakePayment()
        .then(res => {
          if (res && subscribed) {
            setCanMakePayment(true)
          }
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }

    return () => {
      subscribed = false
    }
  }, [paymentRequest])

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on('paymentmethod', onPaymentMethod)
    }
    return () => {
      if (paymentRequest && paymentRequest.off) {
        paymentRequest.off('paymentmethod', onPaymentMethod)
      }
    }
  }, [paymentRequest, onPaymentMethod])

  return [canMakePayment ? paymentRequest : null, loading]
}

type Props = {
  orderDetails: {
    variant: Variant
    shippingDetails: Address
    billingDetails: Address
  }
  product: Product
  quantity: number
  onSuccess?: (args: any) => void
  setOrderId: (arg: string) => void
  onAddToOrdersHistory: (...args: any[]) => void
  shippingFlatRate?: number
  shippingCountry?: string
  onErrorMessage?: (args: any) => void
  onLoaderStatus?: (args: any) => void
  onStatus?: (args: any) => void
}

const PaymentRequestWrapper = ({
  orderDetails,
  product,
  quantity,
  onSuccess,
  setOrderId,
  onAddToOrdersHistory,
  shippingFlatRate,
  shippingCountry,
  onErrorMessage,
  onLoaderStatus,
  onStatus,
}: Props) => {
  const classes = useStyles()
  const stripe = useStripe()
  const [paymentHandler] = usePaymentHandler(setOrderId, onLoaderStatus)
  const handlePayment = useCallback(
    async input => {
      const { status, orderName } = await paymentHandler(input, stripe)
      if (status === stripeStatus.SUCCEEDED) {
        onStatus(PaymentStatus.SUCCESS)
        onAddToOrdersHistory({
          orderId: orderName,
          date: moment().format('D MMM YYYY / k:mm'),
          price: product.myShopPrice + shippingFlatRate,
          photo: product.photos[0],
          website: product.brand.website,
          supportEmail: product.variants[0]?.supportEmail,
          deliveryTime: product.webshop?.deliveryTime,
          privacyPolicyUrl: product.webshop?.privacyPolicyUrl,
          termsAndConditionsUrl: product.webshop?.termsAndConditionsUrl,
          brandName: product.brand?.name,
          returnPolicy: product.webshop?.returnPolicy,
        })
        onSuccess(true)
      } else {
        throw new Error('PAYMENT_NOT_SUCCESSFUL')
      }
    },
    [
      onAddToOrdersHistory,
      onStatus,
      onSuccess,
      paymentHandler,
      product.brand,
      product.myShopPrice,
      product.photos,
      product.variants,
      product.webshop,
      shippingFlatRate,
      stripe,
    ],
  )

  const draft = getCreatePaymentIntentInput(
    product,
    orderDetails,
    quantity,
    null,
    shippingFlatRate,
  )
  const [paymentRequest, loading] = usePaymentRequest({
    options: {
      country: shippingCountry,
      currency: draft.paymentDetails.currency,
      total: {
        label: product.name,
        amount: draft.paymentDetails.amount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestShipping: true,
      shippingOptions: [
        {
          id: 'free-shipping',
          label: 'Free shipping',
          detail: `Average delivery time ${product?.webshop?.deliveryTime ||
            '2 - 5'} days`,
          amount: 0,
        },
      ],
    },
    onPaymentMethod: async ({ complete, paymentMethod, ...data }) => {
      try {
        const orderDetailsWrapper = {
          ...orderDetails,
          shippingDetails: {
            ...data.shippingAddress,
            address: data.shippingAddress.addressLine
              ? data.shippingAddress.addressLine[0]
              : '',
            address2: data.shippingAddress.addressLine
              ? data.shippingAddress.addressLine[1] || ''
              : '',
            postCode: data.shippingAddress.postalCode,
            firstName:
              data.shippingAddress.recipient
                .split(' ')
                .slice(0, -1)
                .join(' ') || data.shippingAddress.recipient,
            lastName: data.shippingAddress.recipient
              .split(' ')
              .slice(-1)
              .join(' '),
          },
          billingDetails: {
            ...paymentMethod.billing_details,
            ...paymentMethod.billing_details.address,
            address: paymentMethod.billing_details.address.line1,
            address2: paymentMethod.billing_details.address.line2 || '',
            postCode: paymentMethod.billing_details.address.postal_code,
            firstName:
              paymentMethod.billing_details.name
                .split(' ')
                .slice(0, -1)
                .join(' ') || paymentMethod.billing_details.name,
            lastName: paymentMethod.billing_details.name
              .split(' ')
              .slice(-1)
              .join(' '),
          },
        }

        onStatus(PaymentStatus.LOADING)

        const input = getCreatePaymentIntentInput(
          product,
          orderDetailsWrapper,
          quantity,
          paymentMethod.id,
          shippingFlatRate,
        )

        await handlePayment(input)

        complete('success')
      } catch (error) {
        console.error(error.code, error.message)
        onErrorMessage(errorMessageFromCode(error.code, error.message))
        onStatus(PaymentStatus.ERROR)
        complete('fail')
      }
    },
  })
  const options = useOptions(paymentRequest)

  if (!paymentRequest && !loading) {
    return (
      <Typography className={classes.note}>
        Save a card in Chrome, or add a card to your Wallet for Safari to use
        Apple Pay or Google Pay
      </Typography>
    )
  }

  return (
    <Box className={classes.container}>
      {paymentRequest && (
        <PaymentRequestButtonElement
          className="PaymentRequestButton"
          options={options as any}
        />
      )}
      {!paymentRequest && loading && (
        <Box className={classes.circularProgress}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}

export default PaymentRequestWrapper
