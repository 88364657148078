import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ProductHeader from './ProductHeader/index'
import ProductSlider from './ProductSlider/ProductSlider'
import { ProductFooter } from './ProductFooter/ProductFooter'
import { Currency, Product } from '../../types'
import cn from 'classnames'
import { normalizeShortUrl, normalizeUrl } from '../../lib/utils'

type Props = {
  product: Product
  onOpenQuantityDialog: () => void
  quantity: number
  onBuy: () => void
  onLike?: () => void
  isLiked?: boolean
  /** Does the brand ship to the IP country ? */
  shopReference: string
  onClose?: () => void

  exchangeRate?: number
  currency?: Currency

  shippingFlatRate?: number
  listType?: string
  onCheckedBrands?: (brandName: string) => void
  checkedBrands?: any
  userId?: string

  shipToSelectedCountry?: boolean
  selectedCountry?: string
}

const useStyles = (props: { listType: string }) =>
  makeStyles((theme: any) => ({
    root: {
      width: 'calc(100vw - 10px)',
      marginBottom: 15,
      boxShadow: '0px 2px 6px 0 rgba(34, 33, 33, 0.04)',
      maxWidth: 400,
      background: theme.palette.white,
      border: '1px solid #EAEAEA',
      position: 'relative',
      height: 'fit-content',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 380,
        marginBottom: 15,
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: props.listType === 'grid-view' ? 790 : 380,
        marginBottom: 15,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: props.listType === 'grid-view' ? 790 : 380,
        marginBottom: 15,
      },
    },
    margin: {
      margin: 5,
    },
  }))

export function ProductCard({
  product,
  onOpenQuantityDialog,
  quantity,
  onBuy,
  isLiked,
  onLike,
  exchangeRate,
  currency,
  onClose,
  shippingFlatRate,
  listType,
  onCheckedBrands,
  checkedBrands,
  shopReference,
  userId,
  shipToSelectedCountry,
  selectedCountry,
}: Props) {
  const classes = useStyles({ listType })()
  return (
    <div
      className={cn(classes.root, !onClose && classes.margin)}
      id={product.id}
      data-testid={product.id}
    >
      <ProductHeader
        logo={product?.brand?.logo}
        brandShortUrl={normalizeShortUrl(
          product?.brand?.name,
          product?.webshop.displayURL || product?.brand?.website,
        )}
        shopUrl={product?.webshop?.displayURL || product?.webshop?.webshopURL}
        settingsId={product?.webshop?.docId}
        shopReference={shopReference}
        onClose={onClose}
        brandName={product?.brand?.name}
        brandWebsite={normalizeUrl(
          product?.webshop.displayURL || product?.brand?.website,
        )}
        onCheckedBrands={onCheckedBrands}
        checkedBrands={checkedBrands}
        discountPercentage={product.discountPercentage}
        userId={userId}
        selectedCountry={selectedCountry}
        commissionBalance={product?.brand?.commissionsBalance}
      />
      <ProductSlider
        pictures={product.photos}
        discountPercentage={product.discountPercentage}
        youtubeUrl={product?.youtubeUrl}
        shipToSelectedCountry={shipToSelectedCountry}
      />
      <ProductFooter
        myPrice={product.myShopPrice + (shippingFlatRate || 0)}
        webShopPrice={product.webShopPrice + (shippingFlatRate || 0)}
        name={product.name}
        onOpenQuantityDialog={onOpenQuantityDialog}
        quantity={quantity}
        onBuy={onBuy}
        onLike={onLike}
        isLiked={isLiked}
        likes={product?.likes}
        description={product.description}
        customDescription={product.customDescription}
        brandName={product?.brand?.name}
        brandShortUrl={normalizeShortUrl(
          product?.brand?.name,
          product?.webshop.displayURL || product?.brand?.website,
        )}
        returnPolicy={product?.webshop.returnPolicy}
        warehouseCountries={product?.webshop?.warehouseCountries}
        deliveryTime={product?.webshop?.deliveryTime}
        ipLocation={selectedCountry}
        shipToIpLocation={shipToSelectedCountry}
        exchangeRate={exchangeRate}
        currency={currency}
        shipToSelectedCountry={shipToSelectedCountry}
        selectedCountry={selectedCountry}
      />
    </div>
  )
}

function productPropsAreEqual(prevProps, nextProps) {
  if (prevProps?.onClose) {
    return false
  }
  return (
    prevProps?.product.id === nextProps?.product.id &&
    prevProps?.product.likes === nextProps?.product.likes &&
    prevProps?.product.myShopPrice === nextProps?.product.myShopPrice &&
    prevProps?.quantity === nextProps?.quantity &&
    prevProps?.exchangeRate === nextProps?.exchangeRate &&
    prevProps?.checkedBrands === nextProps?.checkedBrands &&
    prevProps?.shipToSelectedCountry === nextProps?.shipToSelectedCountry
  )
}

export default React.memo(ProductCard, productPropsAreEqual)
