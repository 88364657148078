import { useEffect } from 'react'
import { useLocalStorage } from 'react-use'
import { IPInfo } from '../types'

const IP_INFO_KEY = 'IP_INFO_KEY'

/**
 * Get the IP info of the user, store it into localStorage.
 * */
export function useGetIPInfo() {
  const [ipInfo, setIpInfo] = useLocalStorage<IPInfo>(IP_INFO_KEY, {})

  useEffect(() => {
    async function fetchIpInfo() {
      try {
        if (!ipInfo.country) {
          const response = await fetch(`
          https://pro.ip-api.com/json?fields=status,message,country,countryCode,region,regionName,city,timezone,currency&key=Cm04gZL53YWp9bp`)
          setIpInfo(await response.json())
        }
      } catch (_) {
        return
      }
    }
    fetchIpInfo()
  })

  return [ipInfo] as Array<any>
}
