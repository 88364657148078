import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AddressForm from '../AddressForm/AddressForm'
import { Address } from '../../types'
import Title from '../Title/Title'

type Props = {
  /** the title of the dialog */
  title: string
  /** call on click continue  */
  onSubmit: (args: any) => void
  /** initial values of the inputs */
  initState?: Address
  onPrevious?: (args: any) => void
  displayBillingCheckbox?: boolean
  privacyPolicyUrl?: string
  termsAndConditionsUrl?: string
  onTermsAndConditions?: (args: any) => void
  isTermsAndConditions?: boolean
  setTermsAndConditions?: (args: any) => void
  isBillingAddressTheSame?: boolean
  setBillingAddressTheSame?: (args: any) => void
  onClose: (args: any) => void
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    maxWidth: 500,
  },
  title: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 25,
    lineHeight: '34px',
    margin: 30,
    color: '#000',
    [theme.breakpoints.up('sm')]: {
      margin: 25,
    },
  },
  input: {
    margin: 15,
    minWidth: 250,
  },
  arrowBack: {
    position: 'absolute',
    top: 35,
    left: 25,
    [theme.breakpoints.up('sm')]: {
      top: 30,
    },
  },
  button: {
    ...theme.styles.button,
    margin: 15,
    width: '90%',
    ...theme.styles.blueButton,
  },
}))

export function DetailsAddressDialog({
  title,
  onSubmit,
  initState,
  onPrevious,
  displayBillingCheckbox = false,
  onTermsAndConditions,
  isTermsAndConditions,
  setTermsAndConditions,
  isBillingAddressTheSame,
  setBillingAddressTheSame,
  onClose,
}: Props) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Title title={title} onClose={onClose} onPrevious={onPrevious} />
      <AddressForm
        onSubmit={onSubmit}
        initState={initState}
        displayBillingCheckbox={displayBillingCheckbox}
        onTermsAndConditions={onTermsAndConditions}
        isTermsAndConditions={isTermsAndConditions}
        setTermsAndConditions={setTermsAndConditions}
        isBillingAddressTheSame={isBillingAddressTheSame}
        setBillingAddressTheSame={setBillingAddressTheSame}
      />
    </div>
  )
}

export default DetailsAddressDialog
